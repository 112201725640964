
import { defineComponent } from "vue";
import { headerWidthFluid, headerLeft } from "@/core/helpers/config";
import { useMsal } from "@/composition-api/useMsal";

export default defineComponent({
  name: "KTHeader",
  props: {
    title: String,
  },
  components: {},
  setup() {
    const { instance } = useMsal();

    const signOut = () => {
      instance.logoutRedirect();
      // useCleanStore();
    };
    return {
      signOut,
      headerWidthFluid,
      headerLeft,
    };
  },
});
