import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  id: "kt_aside_menu_wrapper",
  ref: "scrollElRef",
  class: "hover-scroll-overlay-y my-5 my-lg-5",
  "data-kt-scroll": "true",
  "data-kt-scroll-activate": "{default: false, lg: true}",
  "data-kt-scroll-dependencies": "#kt_aside_logo, #kt_aside_footer",
  "data-kt-scroll-height": "auto",
  "data-kt-scroll-offset": "0",
  "data-kt-scroll-wrappers": "#kt_aside_menu"
}
const _hoisted_2 = {
  id: "#kt_header_menu",
  class: "menu menu-column menu-arrow-gray-500 px-3",
  "data-kt-menu": "true"
}
const _hoisted_3 = { class: "menu-icon" }
const _hoisted_4 = { class: "menu-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menu, (item, i) => {
        return (_openBlock(), _createElementBlock("div", {
          key: i,
          class: "menu-item"
        }, [
          _createVNode(_component_router_link, {
            class: "menu-link",
            "active-class": "active",
            to: item.url
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_3, [
                _createElementVNode("i", {
                  class: _normalizeClass([item.icon, "bi fs-3 text-white"])
                }, null, 2)
              ]),
              _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.translate(item.label)), 1)
            ]),
            _: 2
          }, 1032, ["to"])
        ]))
      }), 128))
    ])
  ], 512))
}