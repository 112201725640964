const DocMenuConfig = [
  {
    heading: "GESTIONE TRIBUNE",
    pages: [
      {
        order: 10,
        heading: "Posti",
        route: "/posti",
        fontIcon: "bi-briefcase-fill ",
      },
      {
        order: 20,
        heading: "Tribuna",
        route: "/tribuna",
        fontIcon: "bi-credit-card-2-front",
      },
      {
        order: 30,
        heading: "Sessioni",
        route: "/sessioni",
        fontIcon: "bi-file-earmark-person",
      },
      {
        order: 40,
        heading: "Persone",
        route: "/persone",
        fontIcon: "bi-person",
      },
    ],
  },
];

export default DocMenuConfig;
